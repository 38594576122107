import { Outlet } from 'react-router-dom';
import SideItem from './sideItem';
import { useResponsive } from 'component/useResponsive';
import { color } from 'assets/css/color/color';
export default function DashBoradLayout() {
  const { screenType } = useResponsive();
  return (
    // <div
    //   classNameName={`boxed_wrapper ltr`}
    //   style={{ background: color.background, minHeight: '100vh' }}>
    //   {/* <div classNameName="d-flex"> */}
    //   <div classNameName="row">
    //     <div classNameName="col-2">
    //       {screenType === 'desktop' && (
    //         <div>
    //           <SideItem />
    //         </div>
    //       )}
    //     </div>
    //     <div classNameName="col-10">
    //       <div>
    //         <div classNameName={screenType === 'desktop' ? 'px-3 pt-3' : 'pt-3'}>
    //           <Outlet />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* </div> */}
    // </div>
    <main
      className="main boxed_wrapper ltr"
      style={{ background: color.background, minHeight: '100vh' }}>
      {screenType === 'desktop' && (
        <aside className="sidebar">
          <div>
            <SideItem />
          </div>
        </aside>
      )}

      <section className="twitter" style={{ width: screenType === 'desktop' ? '82%' : '100%' }}>
        <div className={screenType === 'desktop' ? 'px-3 pt-3' : 'pt-3'}>
          <Outlet />
        </div>
      </section>
    </main>
  );
}
