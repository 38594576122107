import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast, successToast } from 'component/toast';
import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';
import api from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';
import { REACT_KEY } from 'utils/appConstant';

const slice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {}
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

export const userLogin =
  (requestParams, navigate, setIsloading, setLockData) => async (dispatch) => {
    let email = requestParams.get('username');
    let temp_data = {
      email: email,
      password: requestParams.get('password')
    };

    api
      .post(`${SILVERBACK_API.auth.login}`, requestParams, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          let result = response?.data;
          secureLocalStorage.setItem(REACT_KEY.EMAIL_KEY, JSON.stringify(temp_data));
          if (result?.data?.isEmailVerified) {
            successToast(response.data.message, 'loginSuccess');
            if (
              !result?.data?.enableMethods?.mfa_enabled &&
              !result?.data?.enableMethods?.emailAuthentication
            ) {
              secureLocalStorage.setItem(REACT_KEY.EMAIL_KEY, JSON.stringify(requestParams));
              Cookies.set(REACT_KEY?.REACT_APP_TEMP_USER_AUTH, result?.data?.access_token, {
                expires: 1,
                secure: true
              });
              navigate('/net-banking/two-factor-authentication');
            } else {
              if (result?.data?.enableMethods?.mfa_enabled) {
                navigate('/net-banking/verify-google-authentication');
              } else if (result?.data?.enableMethods?.emailAuthentication) {
                navigate('/net-banking/verify-email-authentication');
              }
            }
            setIsloading(false);
          } else if (!result?.data?.isEmailVerified) {
            secureLocalStorage.setItem(REACT_KEY.EMAIL_KEY, JSON.stringify(temp_data));
            let request = {
              otpType: 'phone',
              flag: 'login',
              value: email
            };
            dispatch(getOtpOnEmail(request));
            setIsloading(false);
            navigate('/net-banking/otp');
          } else {
            navigate('/');
            setIsloading(false);
          }
        } else if (response.status == STATUS_CODES.FAILURE) {
          errorToast(response.data.detail, 'support');
          setIsloading(false);
        } else if (response.status == STATUS_CODES.SERVER_ERROR) {
          errorToast('something went wrong');
          setIsloading(false);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          setLockData((lockData) => ({
            ...lockData,
            status: true,
            message: e?.response?.data?.detail
          }));
        } else {
          errorToast(e?.response?.data?.detail, 'support');
        }
        setIsloading(false);
      });
  };

export const verifyOtpApi = (requestParams, handleSuccessVerification) => async () => {
  try {
    await api.post(SILVERBACK_API.auth.verifyOtpApi, requestParams).then((response) => {
      let result = response.data;
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(result.message);
        Cookies.set(REACT_KEY?.REACT_APP_TEMP_USER_AUTH, result.data.access_token, {
          expires: 1,
          secure: true
        });

        handleSuccessVerification(true);
      } else {
        handleSuccessVerification(false);
        errorToast(result.detail);
      }
    });
  } catch (e) {
    handleSuccessVerification(false);
    errorToast(e.response.data.detail);
    return console.error(e.message);
  }
};

export const sendOtp = (requestParams, setIsloading) => async () => {
  try {
    await api.post(SILVERBACK_API.auth.sendOtp, requestParams).then((response) => {
      let result = response.data;
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(result.message);
        setIsloading(false);
      } else {
        errorToast(result.detail);
        setIsloading(false);
      }
    });
  } catch (e) {
    errorToast(e?.response?.data?.detail);
    return console.error(e.message);
  }
};

export const signUpUser = (requestParams, navigate, setIsloading) => async () => {
  api
    .post(`${SILVERBACK_API.auth.signUp}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response.data.message, 'signupSuccess');
        secureLocalStorage.setItem(REACT_KEY.EMAIL_KEY, JSON.stringify(requestParams));
        setIsloading(false);
        navigate('/net-banking/otp');
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast(response.data.detail, 'signupError');
        setIsloading(false);
      }
    })
    .catch((e) => {
      setIsloading(false);
      return errorToast(e?.response?.data?.detail, 'signupError');
    });
};

export const forgotPassWordApi = (requestParams, setIsloading) => async () => {
  api
    .post(`${SILVERBACK_API.auth.forgotPassWord}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response.data.message, 'forgotPass');
        setIsloading(false);
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast(response.data.detail, 'forgotPass');
        setIsloading(false);
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail);
      setIsloading(false);
    });
};

//  change-password
export const changePassword = (requestParams, onClose) => async () => {
  api
    .post(`${SILVERBACK_API.auth.changePassword}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response.data.message, 'forgotPass');
        onClose();
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast(response.data.detail, 'forgotPass');
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      console.log(e);
    });
};

// update coach mark status
export const updateCoachMarkStatus = (requestParams) => async () => {
  api
    .post(`${SILVERBACK_API.updateCoachMarkStatus}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        console.log('Fff');
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast(response.data.detail, 'forgotPass');
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      console.log(e);
    });
};

//  send otp on email for two factor authentication
export const getOtpOnEmail = (requestParams) => async () => {
  api
    .post(`${SILVERBACK_API.auth.getOtpOnEmail}`, requestParams)
    .then((response) => {
      if (response?.status == STATUS_CODES?.SUCCESS) {
        successToast(response?.data?.message);
      } else if (response?.status == STATUS_CODES?.FAILURE) {
        errorToast(response?.data?.detail);
      }
    })
    .catch((e) => {
      errorToast(e?.response.data.detail);
      console.log(e);
    });
};

// exchange token

export const exchangeToken = (token, handleSuccess) => async () => {
  console.log('tt', token);
  axios
    .post(`${process.env.REACT_APP_API_URL + SILVERBACK_API.auth.exchangeToken}`, ' ', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      if (response?.status == STATUS_CODES?.SUCCESS) {
        handleSuccess(response?.data);
      } else if (response?.status == STATUS_CODES?.FAILURE) {
        handleSuccess();
        errorToast(response?.data?.detail);
      }
    })
    .catch((e) => {
      handleSuccess();
      errorToast(e?.response.data.detail);
      console.log(e);
    });
};
