/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { errorToast, successToast } from 'component/toast';
import api from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';
import { getAssets, getLiabilities } from './profile';

const slice = createSlice({
  name: 'plaid',
  initialState: {
    plaidExchangeData: null,
    isLoading: false
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    plaidExchangeSuccess: (state, action) => {
      state.plaidExchangeData = action.payload.data;
    },
    plaidExchangeFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    plaidExchangeIncomeSuccess: (state, action) => {
      state.plaidExchangeData = action.payload.data;
    },
    plaidExchangeIncomeFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    }
  }
});

const {
  loadingSuccess,
  plaidExchangeSuccess,
  plaidExchangeFailed,
  plaidExchangeIncomeSuccess,
  plaidExchangeIncomeFailed
} = slice.actions;

/**********************ACTIONS************************************************ */

// get Details For Signature

export const plaidExchange =
  (data, status, setShowDialog, setIsloading, handleFetch) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    try {
      await api.post(SILVERBACK_API.plaid.plaidExchangeURL, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(plaidExchangeSuccess(response.data));
          successToast(response.data.message, 'success');
          if (setShowDialog) {
            setShowDialog(false);
          }
          if (handleFetch) {
            handleFetch();
          }
          dispatch(getLiabilities());
          dispatch(getAssets());
        } else {
          dispatch(plaidExchangeFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        setIsloading(false);
        dispatch(loadingSuccess(false));
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const plaidExchangeIncome =
  (data, status, setShowDialog, setIsloading) => async (dispatch) => {
    dispatch(loadingSuccess(status));
    try {
      await api.post(SILVERBACK_API.plaid.plaidExchangeIncomeURL, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(plaidExchangeIncomeSuccess(response.data));
          successToast(response.data.message, 'success');
          dispatch(getAssets());
          setShowDialog(false);
        } else {
          dispatch(plaidExchangeIncomeFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        setIsloading(false);
        dispatch(loadingSuccess(false));
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export default slice.reducer;
