const API_VERSION = {
  v1: 'api/v1'
};
export const SILVERBACK_API = {
  subscribeUs: `${API_VERSION.v1}/save-subscribers`,
  contantUs: `${API_VERSION.v1}/contact-support`,
  stateList: `${API_VERSION.v1}/states`,
  getFeaturesList: `${API_VERSION.v1}/feature/list`,
  financeAccountList: `${API_VERSION.v1}/financialAccount/financial-accounts-list`,
  verifyCaptcha: `${API_VERSION.v1}/cms/user/verify-recaptcha`,
  siteSetting: `${API_VERSION.v1}/site-setting`,
  getNotificationList: `${API_VERSION.v1}/common/notification/list`,
  readNotification: `${API_VERSION.v1}/common/notification/read`,
  atmLocator: `${API_VERSION.v1}/cards/atm-locator`,
  uploadBase64: `${API_VERSION.v1}/document/upload/base64`,
  getCurrencyCodesList: `${API_VERSION.v1}/currency-code-list`,
  getCurrencyConvert: `${API_VERSION.v1}/currency-conversion`,
  getTicketsList: `${API_VERSION.v1}/ticket/user-list`,
  getTicketsCategoryList: `${API_VERSION.v1}/ticket/categories`,
  getTicketsSubcategoriesList: `${API_VERSION.v1}/ticket/subcategories`,
  raiseTicket: `${API_VERSION.v1}/ticket/raise`,
  getMyBankAccount: `${API_VERSION.v1}/financialAccount/financial-accounts-list`,
  updateProfileSignature: `${API_VERSION.v1}/update-profile-signature`,
  getManageNotification: `${API_VERSION.v1}/user/get-manage-notification`,
  setManageNotification: `${API_VERSION.v1}/user/set-manage-notification`,
  getBannerList: `${API_VERSION.v1}/banner/list`,
  updateCoachMarkStatus: `${API_VERSION.v1}/user/coach-mark`,
  getPressReleaseList: `${API_VERSION.v1}/blog/list`,
  getPressReleaseDetails: `${API_VERSION.v1}/blog/detail`,
  downloadBankStatement: `${API_VERSION.v1}/financialAccount/pdf-statement`,
  downloadBankStatementById: `${API_VERSION.v1}/financialAccount/download/statement`, // For mobile users

  card: {
    cardsList: `${API_VERSION.v1}/cards/get-cards`,
    activeDeactive: `${API_VERSION.v1}/cards/suspend-activate-card`,
    orderPhysicalCard: `${API_VERSION.v1}/cards/order-physical-card`,
    virtualCardApi: `${API_VERSION.v1}/cards/issue-virtual-card`,
    sendOtpForMoblile: `${API_VERSION.v1}/cards/send-otp-to-set-pin`,
    virifyOTP: `${API_VERSION.v1}/cards/verify-otp-to-set-pin`,
    setPinApi: `${API_VERSION.v1}/cards/set-pin`,
    fetchNearByAtm: `${API_VERSION.v1}/cards/atm-locator`,
    getCardsTranscationlist: `${API_VERSION.v1}/cards/transaction-history`,
    getPhysicalCardDetails: `${API_VERSION.v1}/cards/physical-card-details`,
    cancelPhysicalCard: `${API_VERSION.v1}/cards/cancel-physical-card`,
    getFullCardDetails: `${API_VERSION.v1}/cards/card-full-detail`,
    changeCardSpendLimit: `${API_VERSION.v1}/cards/change-card-limit`,
    getDeliveryMethod: `${API_VERSION.v1}/cards/delivery-methods`
  },
  auth: {
    login: `${API_VERSION.v1}/user/auth/login`,
    signUp: `${API_VERSION.v1}/user/auth/signup`,
    verifyOtpApi: `${API_VERSION.v1}/auth/verify-email-otp`,
    sendOtp: `${API_VERSION.v1}/auth/send-verification-email`,
    forgotPassWord: `${API_VERSION.v1}/auth/forgot-password`,
    changePassword: `${API_VERSION.v1}/auth/change-password`,
    configureAuth: `${API_VERSION.v1}/cms/user/configure-auth`,
    configureTOTP: `${API_VERSION.v1}/cms/user/configure-totp`,
    getOtpOnEmail: `${API_VERSION.v1}/auth/otp-request`,
    exchangeToken: `${API_VERSION.v1}/user/auth/exchange-token`
  },
  profile: {
    getProfileDetail: `${API_VERSION.v1}/user/me`,
    updateProfilePicture: `${API_VERSION.v1}/update-picture`,
    updateProfile: `${API_VERSION.v1}/user/update-profile`,
    getOtp: `${API_VERSION.v1}/user/send-otp-to-update-profile`,
    verifyOtp: `${API_VERSION.v1}/user/verify-otp-to-update-profile`,
    assetsList: `${API_VERSION.v1}/plaid/assets/accounts`,
    getInitURL: `${API_VERSION.v1}/plaid/init`,
    getIncomeListURL: `${API_VERSION.v1}/plaid/payroll-income`,
    realEstateListURL: `${API_VERSION.v1}/plaid/realestate/account`,
    insuranceListURL: `${API_VERSION.v1}/insurance/list`,
    accountURL: `${API_VERSION.v1}/plaid/account`,
    otherTypeURl: `${API_VERSION.v1}/plaid/other/account/`,
    liabilitiesListURl: `${API_VERSION.v1}/plaid/liabilities/accounts`,
    otherTypeListURl: `${API_VERSION.v1}/plaid/other/asset_debt_type`,
    addOtherTypeURl: `${API_VERSION.v1}/plaid/other/save`,
    goalDetailURL: `${API_VERSION.v1}/goal/info`,
    goalListURL: `${API_VERSION.v1}/goal/list`,
    iconListURl: `${API_VERSION.v1}/goal/icon/list`,
    addGoalsURL: `${API_VERSION.v1}/goal/add`,
    updateGoalURL: `${API_VERSION.v1}/goal/update`,
    deleteGoalURL: `${API_VERSION.v1}/goal/delete`,
    goalDetailApiURL: `${API_VERSION.v1}/goal/detail`,
    updateAmountApiURl: `${API_VERSION.v1}/goal/update/amount`,
    realEstateInfoURL: `${API_VERSION.v1}/realestate/info`,
    addRealEstateApiURL: `${API_VERSION.v1}/realestate/add`,
    getInitIncomeURL: `${API_VERSION.v1}/plaid/init/income`,
    worthSummaryURL: `${API_VERSION.v1}/plaid/net-worth/summary`,
    getAssetStatisticsURL: `${API_VERSION.v1}/plaid/assets/accounts`,
    getIncomeStatisticsURL: `${API_VERSION.v1}/plaid/income/statistics`,
    getAccountStatisticTypeURL: `${API_VERSION.v1}/plaid/assets/statistics`,
    getAccountStatisticLiabilityURL: `${API_VERSION.v1}/plaid/liabilities/statistics`,
    realEstateURL: `${API_VERSION.v1}/realestate/statistics`,
    otherTypeStatisticsURL: `${API_VERSION.v1}/plaid/other/statistics`,
    getLiabilityStatisticsURL: `${API_VERSION.v1}/plaid/liabilities/accounts`,
    downloadDirectDepositForm: `${API_VERSION.v1}/fundsMovement/direct-deposit-form`,
    getDocumentsList: `${API_VERSION.v1}/document/document-list`
  },
  plaid: {
    plaidExchangeURL: `${API_VERSION.v1}/plaid/exchange-token`,
    plaidExchangeIncomeURL: `${API_VERSION.v1}/plaid/income/exchange-token`
  },
  networth: {
    financialAdviceRequest: `${API_VERSION.v1}/financialAdviceRequest/save`,
    uploaDocument: `${API_VERSION.v1}/document/upload`
  },

  insurance: {
    getInsuranceTypes: `${API_VERSION.v1}/insurance/types`,
    getInsurancePeriod: `${API_VERSION.v1}/insurance/periods`,
    createInsurance: `${API_VERSION.v1}/insurance/create`,
    getCashValueApproaches: `${API_VERSION.v1}/insurance/cash-value-approaches`,
    getDistributionsList: `${API_VERSION.v1}/insurance/distributions`,
    getDeathBenefitsList: `${API_VERSION.v1}/insurance/death-benefits`,
    getBenefitPeriods: `${API_VERSION.v1}/insurance/benefit-periods`,
    getInsuranceYear: `${API_VERSION.v1}/insurance/years`,
    getTaxesList: `${API_VERSION.v1}/insurance/taxes`,
    getInflationTypes: `${API_VERSION.v1}/insurance/inflation-types`,
    getEliminationPeriod: `${API_VERSION.v1}/insurance/elimination-periods`,
    getBenefitsTypes: `${API_VERSION.v1}/insurance/benefit-types`,
    getInsuranceDetails: `${API_VERSION.v1}/insurance/get`,
    updateInsurance: `${API_VERSION.v1}/insurance/update`,
    deleteInsurance: `${API_VERSION.v1}/insurance/delete`,
    deleteRealEstate: `${API_VERSION.v1}/realestate/delete`,
    deleteOtherAssest: `${API_VERSION.v1}/plaid/other/delete`
  },
  estate: {
    getDetailsForSignature: `${API_VERSION.v1}/estate/management/details-for-signature`,
    submitSignature: `${API_VERSION.v1}/estate/management/signature`,
    getManagmentRelationshipList: `${API_VERSION.v1}/estate/management/relationship`,
    getManagmentOptions: `${API_VERSION.v1}/estate/management/options`,
    saveEstateManagement: `${API_VERSION.v1}/estate/management/save`,
    getEstateList: `${API_VERSION.v1}/estate/management/list`,
    reshareSignatureLink: `${API_VERSION.v1}/estate/management/reshare-signature-link`,
    getEstateDetails: `${API_VERSION.v1}/estate/management/details`,
    getRemainingLimits: `${API_VERSION.v1}/estate/management/remaining-limits`,
    updateEstateManagement: `${API_VERSION.v1}/estate/management/update`,
    compareSignature: `${API_VERSION.v1}/compare-identical-signature`
  },
  funds: {
    // external account
    getExternalAccounts: `${API_VERSION.v1}/fundsMovement/get-all-external-account`,
    getFundsOtp: `${API_VERSION.v1}/financialAccount/send-verification-code`,
    addExternalAccounts: `${API_VERSION.v1}/fundsMovement/add-external-account`,
    deleteExternalAccounts: `${API_VERSION.v1}/fundsMovement/close-external-account`,
    // payee
    getPayeesList: `${API_VERSION.v1}/fundsMovement/get-all-payees`,
    deletePayee: `${API_VERSION.v1}/fundsMovement/delete-payee`,
    getAccountInfo: `${API_VERSION.v1}/fundsMovement/check-payee`,
    addPayee: `${API_VERSION.v1}/fundsMovement/add-payee`,
    // send money
    getfinancialAccountsDetails: `${API_VERSION.v1}/fundsMovement/financial-account-details`,
    achTransferMoney: `${API_VERSION.v1}/fundsMovement/ach-transfer`,
    addFunds: `${API_VERSION.v1}/fundsMovement/deposit-funds`,

    //  get balance
    getAccountBalance: `${API_VERSION.v1}/fundsMovement/external-account-balance`,

    // transcationlist
    getTranscationlist: `${API_VERSION.v1}/fundsMovement/transfer-list`,
    disputeTranscation: `${API_VERSION.v1}/fundsMovement/transaction-dispute`,
    getTranscationDetails: `${API_VERSION.v1}/fundsMovement/transfer-details`
  }
};

export const STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
  NOT_AUTHORIZED: 401,
  SERVER_ERROR: 500
};
