/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';
import { errorToast, successToast } from 'component/toast';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'card',
  initialState: {
    cardListData: null,
    activeDeactiveData: null,
    orderCardData: null,
    virtualCardData: null,
    senOtpData: null,
    setPinData: null,
    atmData: null,
    currencyCodeList: [],
    manageNotificationData: null,
    creditTranscationData: [],
    physicalCardDetail: null
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    //For listing

    cardListSuccess: (state, action) => {
      state.cardListData = action.payload.data?.cardList;
    },
    cardListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For Acitivate Deactivate Card
    activeDeactiveSuccess: (state, action) => {
      let index = state.cardListData?.findIndex((item) => item.id === action.payload.updateIndex);
      state.cardListData[index].status = action.payload.status ? 'ACTIVE' : 'SUSPENDED';
    },
    activeDeactiveFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For OrderPhysicalCard
    orderPhysicalCardSucceess: (state, action) => {
      state.orderCardData = action.payload.data;
    },
    orderPhysicalCardFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For VirtualCard
    virtualCardSuccess: (state, action) => {
      state.virtualCardData = action.payload.data;
    },
    virtualCardFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For VirtualCard
    sendOtpForMobileSuccess: (state, action) => {
      state.senOtpData = action.payload.data;
    },
    sendOtpForMobileFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For Verify OTP
    verifyOtpSuccess: (state, action) => {
      state.virifyOTPData = action.payload.data;
    },
    verifyOtpFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For setPinApi
    setPinApiSuccess: (state, action) => {
      state.setPinData = action.payload.data;
    },
    setPinApiFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    //For nearByAtm
    setNearByAtmSuccess: (state, action) => {
      state.atmData = action.payload.data;
    },
    setNearByAtmFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    getCurrencyCodesListSuccess: (state, action) => {
      state.currencyCodeList = action.payload;
    },
    getManageNotificationSuccess: (state, action) => {
      state.manageNotificationData = action.payload;
    },
    getCardsTranscationlistSuccess: (state, action) => {
      state.creditTranscationData = action.payload;
    },
    getPhysicalCardDetailsSuccess: (state, action) => {
      state.physicalCardDetail = action.payload;
    },
    cancelPhysicalCardSuccess: (state, action) => {
      state.physicalCardDetail.node.orderState.status = action.payload;
    },
    getDeliveryMethodSuccess: (state, action) => {
      state.deliveryMethodsData = action.payload;
    }
  }
});

// ** Actions **
const {
  getDeliveryMethodSuccess,
  cardListSuccess,
  cardListFailed,
  loadingSuccess,
  activeDeactiveSuccess,
  activeDeactiveFailed,
  orderPhysicalCardSucceess,
  orderPhysicalCardFailed,
  virtualCardSuccess,
  getPhysicalCardDetailsSuccess,
  sendOtpForMobileSuccess,
  sendOtpForMobileFailed,
  verifyOtpSuccess,
  verifyOtpFailed,
  setPinApiSuccess,
  setPinApiFailed,
  setNearByAtmSuccess,
  setNearByAtmFailed,
  getCurrencyCodesListSuccess,
  getManageNotificationSuccess,
  getCardsTranscationlistSuccess,
  cancelPhysicalCardSuccess
} = slice.actions;

export const cardListing = (data, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.card.cardsList, data).then((response) => {
      if (response) {
        dispatch(cardListSuccess(response.data));
        if (setIsloading) {
          setIsloading(false);
        }
      } else {
        dispatch(cardListFailed(response.error));
        if (setIsloading) {
          setIsloading(false);
        }
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const activeDeactive =
  (data, setShowDeactiveModal, indexView, setReasonValue, setBtnLoader) => async (dispatch) => {
    try {
      await api.post(SILVERBACK_API.card.activeDeactive, data).then((response) => {
        if (response) {
          let id = data?.paymentCardId;
          dispatch(
            activeDeactiveSuccess({
              ...response?.data,
              updateIndex: id,
              status: data?.status
            })
          );
          successToast(response.data?.message, 'support');
          setShowDeactiveModal(false);
          setReasonValue('');
        } else {
          dispatch(activeDeactiveFailed(response.error));
          errorToast(response.data?.message, 'support');
        }
        setBtnLoader(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const orderPhysicalCard = (data, handleOrderSuccess) => async (dispatch) => {
  api
    .post(SILVERBACK_API.card.orderPhysicalCard, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        toast.success(response.data.message);
        handleOrderSuccess(true);
      } else {
        handleOrderSuccess(false);
        errorToast(response?.data?.detail);
      }
    })
    .catch((e) => {
      handleOrderSuccess(false);
      errorToast(e?.response?.data?.detail, 'support');
    });
};
export const virtualCardApi =
  (data, setShowVirtualCardModal, setVirtualCardForm, setBtnLoader) => async (dispatch) => {
    api
      .post(`${SILVERBACK_API.card.virtualCardApi}`, data)
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          var data = {
            filter: 'VIRTUAL'
          };
          dispatch(cardListing(data));
          setShowVirtualCardModal(false);
          dispatch(virtualCardSuccess(successToast(response.data?.message, 'support')));
          if (setVirtualCardForm) {
            setVirtualCardForm({});
          }
        } else if (response.status == STATUS_CODES.FAILURE) {
          errorToast(response.data.detail, 'support');
        }
        if (setBtnLoader) {
          setBtnLoader(false);
        }
        // dispatch(loadingSuccess(false));
      })
      .catch((e) => {
        setBtnLoader(false);
        return errorToast(e?.response?.data?.detail, 'support');
      });
  };

export const sendOtpForMobile =
  (data, setShowPinGenerationModal, setShowOtpView, setBtnLoader) => async (dispatch) => {
    api
      .post(`${SILVERBACK_API.card.sendOtpForMoblile}`, data)
      .then((response) => {
        if (response.status == STATUS_CODES.SUCCESS) {
          setShowPinGenerationModal(false);
          dispatch(
            setShowOtpView(true),
            sendOtpForMobileSuccess(successToast(response.data?.message, 'support'))
          );
        } else if (response.status == STATUS_CODES.FAILURE) {
          dispatch(sendOtpForMobileFailed(errorToast(response.data?.detail, 'support')));
        }
        setBtnLoader(false);
        // dispatch(loadingSuccess(false));
      })
      .catch((e) => {
        errorToast(e?.response?.data?.detail);
        setBtnLoader(false);
        return console.error(e);
      });
  };

export const verifyOtpApi =
  (data, setShowOtpView, setShowPinGenerationModal, setBtnLoader) => async (dispatch) => {
    try {
      await api.post(SILVERBACK_API.card.virifyOTP, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          setShowOtpView(false);
          dispatch(
            setShowPinGenerationModal(true),
            verifyOtpSuccess(successToast(response.data?.message, 'support'))
          );
        } else {
          dispatch(verifyOtpFailed(errorToast(response.data?.detail, 'support')));
        }
        setBtnLoader(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setPinApi = (data, setShowSetPinView, setBtnLoader) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.card.setPinApi, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(setPinApiSuccess(successToast(response.data?.message, 'support')));
        setShowSetPinView(false);
      } else {
        dispatch(setPinApiFailed(errorToast(response.data?.detail, 'support')));
      }
      setBtnLoader(false);
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchNearByAtm = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.card.fetchNearByAtm, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(setNearByAtmSuccess(successToast('success', response.data?.message, 'support')));
      } else {
        dispatch(setNearByAtmFailed(errorToast('error', response.data?.detail, 'support')));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCurrencyCodesList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.getCurrencyCodesList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getCurrencyCodesListSuccess(response.data.data.data));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getManageNotification = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.getManageNotification).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getManageNotificationSuccess(response?.data?.data));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCardsTranscationlist =
  (data, setIsloading, setLoadMoreLoader) => async (dispatch) => {
    try {
      await api.post(SILVERBACK_API.card.getCardsTranscationlist, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getCardsTranscationlistSuccess(response?.data?.data));
        } else {
          errorToast(response.data?.detail, 'support');
        }
        setIsloading(false);
        setLoadMoreLoader(false);
        dispatch(loadingSuccess(false));
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
// get physical cards details
export const getPhysicalCardDetails = (cardId, setCardLoader) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.card.getPhysicalCardDetails}/${cardId}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        setCardLoader(false);
        dispatch(getPhysicalCardDetailsSuccess(response?.data?.data?.cardDetails));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
//  cancel physical card

export const cancelPhysicalCard = (cardId) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.card.cancelPhysicalCard}/${cardId}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        toast.success(response?.data?.message);

        dispatch(
          cancelPhysicalCardSuccess(
            response?.data?.data?.cardDetails?.cancelPhysicalPaymentCardOrder?.orderState?.status
          )
        );
      } else {
        errorToast(response.data?.detail, 'support');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get full card details
export const getFullCardDetails = (request, setCardLoader) => async (dispatch) => {
  try {
    await api.post(`${SILVERBACK_API.card.getFullCardDetails}`, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getPhysicalCardDetailsSuccess(response?.data?.data?.cardDetails));
        setCardLoader(false);
      } else {
        errorToast(response.data?.detail, 'support');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//  change card spend limit

export const changeCardSpendLimit = (data, handleChangeSuccess) => async (dispatch) => {
  api
    .post(SILVERBACK_API.card.changeCardSpendLimit, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        toast.success(response.data.message);
        handleChangeSuccess(true);
      } else {
        handleChangeSuccess(false);
        errorToast(response.data?.detail);
      }
    })
    .catch((e) => {
      handleChangeSuccess(false);
      errorToast(e?.response?.data?.detail, 'support');
    });
};

// get Delivery Method
export const getDeliveryMethod = () => async (dispatch) => {
  api
    .get(SILVERBACK_API.card.getDeliveryMethod)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDeliveryMethodSuccess(response?.data?.data?.methods));
      } else {
        errorToast(response.data?.detail);
      }
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
    });
};

export default slice.reducer;
