import axios from 'axios';
import Cookies from 'js-cookie';
import secureLocalStorage from 'react-secure-storage';
import { v4 as uuidv4 } from 'uuid';

export const setAccessVal = (accessVal) => {
  Cookies.set(process.env.REACT_APP_USER_AUTH, accessVal, { expires: 1, secure: true });
};

export const getAccessVal = () => {
  let token = Cookies.get(process.env.REACT_APP_USER_AUTH);
  return token ?? '';
};

export const signOut = () => {
  Cookies.remove(process.env.REACT_APP_USER_AUTH);
  Cookies.remove('siteSettingData');
  secureLocalStorage.clear();
  window.location.assign(`/net-banking/login`);
};

export const isSignedIn = getAccessVal() ? true : false;

export const capitalizeFirstLetter = (string) => {
  let val = string.replace(/_/g, ' ');
  const toTitleCase = val.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
  return toTitleCase;
};

export function currencyFormat(num) {
  if (num) {
    return '$ ' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    return '$ 0.00';
  }
}

export function addTotalAssets(data) {
  return data;
}

function isEmptyValue(value) {
  if (value == null) return true; // null or undefined
  if (typeof value === 'string' && value.trim() === '') return true; // empty string
  if (typeof value === 'number' && value === 0) return true; // number zero
  // if (typeof value === 'boolean' && value === false) return true; // boolean false
  if (Array.isArray(value) && value.length === 0) return true; // empty array
  if (typeof value === 'object' && Object.keys(value).length === 0) return true; // empty object
  return false;
}

export const areAllKeysEmpty = (obj) => {
  return Object.values(obj).every(isEmptyValue);
};
export const onlyNumber = (input) => {
  const regex = /^[0-9]+$/;
  if (regex.test(input)) {
    return true;
  } else {
    return false;
  }
};
export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};
// export const capitalizeFirstLetter = (string) => {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// };

export const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return '';
  }
};

export const getDeviceName = () => {
  return navigator.userAgent;
};

export const getOSVersion = () => {
  const userAgent = navigator.userAgent;
  let os = '';
  if (userAgent.indexOf('Windows NT 10.0') !== -1) os = 'Windows 10';
  else if (userAgent.indexOf('Windows NT 6.1') !== -1) os = 'Windows 7';
  else if (userAgent.indexOf('Mac OS X') !== -1) os = 'macOS';
  else if (userAgent.indexOf('Android') !== -1) os = 'Android';
  else if (userAgent.indexOf('iPhone') !== -1) os = 'iOS';
  return os;
};

export const getDeviceId = () => {
  // Check if the device ID is already saved in localStorage
  let deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    // Generate a new ID and store it in localStorage
    deviceId = uuidv4();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
};

export const maskNumber = (num) => {
  const maskLength = 5; // Number of characters to mask with *
  const numStr = num.toString(); // Convert number to string to handle both types
  const maskedPart = '*'.repeat(Math.min(maskLength, numStr.length)); // Mask first 5 characters
  const visiblePart = numStr.slice(maskLength); // Show remaining digits
  return `${maskedPart}${visiblePart}`; // Combine masked and visible parts
};

export const validateDecimalInput = (value) => {
  // Regular expression to allow numbers and only one decimal place
  const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
  // Test the value against the regex
  if (regex.test(value)) {
    return true; // The value is valid
  } else {
    return false; // The value is invalid
  }
};

export const checkFeaturesStatus = (array, id) => {
  let obj = array?.find((item) => item?.type === id);
  if (obj?.status) {
    return true;
  } else {
    return false;
  }
};
