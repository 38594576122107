import React from 'react';
import Animate from 'react-smooth';

export default function LazyLoading() {
  return (
    <Animate from={{ opacity: 0 }} to={{ opacity: 1 }} easing="ease-in">
      <div style={{ minHeight: '100vh', backgroundColor:'#0e0e0e' }}></div>
    </Animate>
  );
}
