import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import englishLang from 'assets/translations/en.json';
const resources = {
  en: {
    translation: englishLang
  },
 
};

const fallbackLng = ['en'];
const availableLanguages = ['en'];

i18n
  .use(HttpApi) // load translations using http (default public/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.
    preload: ['en'],
    detection: {
      checkWhitelist: true // options for l anguage detection
    },
    supportedLngs: availableLanguages,
    react: {
      useSuspense: false,
      updateTransComponent: true
    },
    debug: false,
    whitelist: availableLanguages,
    defaultTransParent: 'div',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i']
  });

export default i18n;
