import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './slice/auth';
import contact from './slice/contact';
import profile from './slice/profile';
import card from './slice/card';
import networth from './slice/networth';
import estate from './slice/estate';
import plaid from './slice/plaid';
import funds from './slice/funds';


const rootReducer = combineReducers({
  auth,
  contact,  
  profile,
  card,
  networth,
  estate,
  plaid,
  funds
});
const store = configureStore({
  reducer: rootReducer
});
export default store;
