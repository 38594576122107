import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const successToast = (message, toastId) => {
  toast.success(message, toastId);
};

export const errorToast = (message, toastId) => {
  toast.error(message, toastId);
};

export const infoToast = (message, toastId) => {
  toast.info(message, toastId);
};
