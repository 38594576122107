'use client';
import { Outlet, useNavigate } from 'react-router-dom';
import DataBg from '../DataBg';
import Logo from 'assets/images/Logo1.webp';
import './index.css';
import { useResponsive } from 'component/useResponsive';
import { color } from 'assets/css/color/color';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import bannerImage1 from 'assets/images/customer-portal/auth-banner-1.webp';
import bannerImage2 from 'assets/images/customer-portal/auth-banner-2-proffesional.webp';
import bannerImage3 from 'assets/images/customer-portal/auth-banner-3.webp';
import bannerImage4 from 'assets/images/customer-portal/auth-banner-4.webp';

export default function AuthLayout() {
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
    // loop: true,
    // Navigation
    navigation: {
      nextEl: '.h1n',
      prevEl: '.h1p'
    },

    // Pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  const authBannerData = [
    {
      image: bannerImage1,

      heading: 'Download the Silverback Mobile Banking App.',
      subHeading: 'An app for convenient and secure access to all our financial services.'
    },
    {
      image: bannerImage2,
      heading: ' Easily transfer money securely and swiftly.',
      subHeading: `Transfer your money securely and swiftly with Silverback's seamless money transfer
      services.`
    },
    {
      image: bannerImage3,
      heading: 'Manage and grow your net worth.',
      subHeading: ` Manage your net worth effortlessly with Silverback's comprehensive financial tools.`
    },
    {
      image: bannerImage4,
      heading: 'Unlock personalized wealth management solutions.',
      subHeading:
        ' Unlock personalized wealth management solutions tailored to your financial goals with Silverback'
    }
  ];
  return (
    <>
      <DataBg />
      <div
        id="#top"
        style={{
          background: 'black',
          minHeight: '100vh',
          maxHeight: 'auto',
          position: 'relative',
          width: '100%'
        }}
        className={`boxed_wrapper ltr `}>
        <div className="row">
          {screenType === 'desktop' && (
            <div
              className="col-6 "
              style={{
                minHeight: '100vh',
                maxHeight: 'auto',
                width: '50%',
                background: color.background
              }}>
              <Swiper
                {...swiperOptions}
                className="banner-carousel"
                style={{ background: color.background }}>
                {authBannerData?.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <img src={item.image} alt="" width={'67%'} />
                        </div>

                        <div className="pt-5 text-center px-5">
                          <div
                            style={{
                              color: color.highLightColor,
                              fontSize: 23,
                              fontWeight: 'bold'
                            }}>
                            {item.heading}
                          </div>

                          <h6 style={{ lineHeight: 2 }} className="textLinearBackground px-5">
                            {item?.subHeading}
                          </h6>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
          <div className="col-lg-6 col-md-12 col-sm-12 py-4 bg-black  ">
            <div
              className="content-box  d-flex align-items-center  "
              style={{ minHeight: '90vh', maxHeight: 'auto' }}>
              <div className={`col-${screenType === 'desktop' ? 9 : 11} mx-auto mt-3 `}>
                <div style={{ textAlign: 'center', marginTop: 40 }} onClick={() => navigate('/')}>
                  <img src={Logo} width={'45%'} />
                </div>

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
