import React, { useState } from 'react';
import logo from 'assets/images/Logo1.webp';
import { navConfig } from './config';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './index.css';
import SVDialog from 'component/SVdialog';
import { signOut } from 'utils/constantFun';
import { useResponsive } from 'component/useResponsive';

export default function SideItem() {
  let location = useLocation();
  const { screenType } = useResponsive();
  const [showDialog, setShowDialog] = useState(false);
  const handleLogout = () => {
    setShowDialog(true);
  };

  return (
    <>
      <SVDialog
        show={showDialog}
        title={'Logout'}
        btnTitle={'Logout'}
        handleAgree={() => {
          signOut();
        }}
        handleClose={() => setShowDialog(false)}
        Children={
          <h5 className="textLinearBackground mt-3">{'Are you sure you want to logout ?'}</h5>
        }
      />
      <div style={{ background: 'black' }}>
        <Link to={'/customer/dashboard'}>
          <div className="d-flex justify-content-center pt-5">
            <img src={logo} alt="" width={'70%'} />
          </div>{' '}
        </Link>
        <div className="mt-5 d-grid mb-5 ">
          {navConfig?.map((item, index) => {
            let pathArray = location.pathname.split('/');
            const active =
              location.pathname === item.path ? true : item.subPath === pathArray[2] ? true : false;
            return (
              <Link
                key={index}
                to={item.path}
                style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{ height: 50 }}
                  className={active ? 'linearBackground sideItem' : 'sideItem'}>
                  <img
                    src={item.icon}
                    alt=""
                    width={'22px'}
                    style={{ filter: active ? 'brightness(0)' : '' }}
                  />
                  <div
                    className=" link"
                    style={{
                      paddingLeft: '1rem',
                      paddingTop: '0.1rem',
                      color: active ? 'black' : '#848482'
                    }}>
                    {item?.title}
                  </div>
                </div>
              </Link>
            );
          })}
          <div>
            <div
              className="sideItem"
              style={{ position: 'absolute', bottom: screenType === 'mobile' ? 60 : 20, left: 20 }}
              onClick={handleLogout}>
              <Icon icon={'heroicons-outline:logout'} width={30} color="red" />
              <div
                className=" link"
                style={{ paddingLeft: '2rem', paddingTop: '0.4rem', color: 'red' }}>
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
