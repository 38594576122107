import dashboardIcon from 'assets/images/SB-Icons/dashboard.svg';
import cardsIcon from 'assets/images/SB-Icons/cards-white-icon.svg';
import NetWorthIcon from 'assets/images/SB-Icons/networth.svg';
import SupportIcon from 'assets/images/SB-Icons/support-icon.svg';

export const navConfig = [
  {
    title: 'Dashboard',
    path: '/customer/dashboard',
    icon: dashboardIcon,
    subPath: 'dashboard'
  },

  {
    title: 'Cards',
    path: '/customer/cards',
    subPath: 'cards',
    icon: cardsIcon
  },
  {
    title: 'Net Worth',
    path: '/customer/net-worth',
    icon: NetWorthIcon,
    subPath: 'net-worth'
  },
  // {
  //   title: 'Wealth Insight',
  //   path: '/customer/wealth-insight',
  //   icon: WealthIcon,
  //   subPath: 'wealth-insight'
  // },
  {
    title: 'Support',
    path: '/customer/support',
    icon: SupportIcon,
    subPath: 'support'
  }
  // {
  //   title: 'Contact Us',
  //   path: '/customer/contact',
  //   icon: 'lucide:contact-round'
  // }
];
