import Cookies from 'js-cookie';
import packageJson from '../../package.json';
import secureLocalStorage from 'react-secure-storage';

export const DEFAULT_APP = {
  PHONE_CODE: '+1',
  COUNTRY: '🇺🇸'
};

export const getCurrentVersion = () => {
  return packageJson.version;
};

export const isObjectEmpty = (objectName) => {
  return Object?.keys(objectName)?.length === 0;
};
export const validateEmpty = (str) => {
  return !str || str.length === 0;
};
export const isValidNumber = (str) => {
  const pattern = /^[0-9\b]+$/;
  return pattern.test(str);
};

export const validEmail = (val) => {
  const pattern = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  return pattern.test(val);
};

export const validPassword = (val) => {
  //let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  let pattern =
    /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;
  return pattern.test(val);
};
const temp = Cookies.get('set_temp_site_setting_data');
const temp_2 = secureLocalStorage.getItem('siteSettingData');
export const SITE_SETTING = temp ? JSON.parse(temp) : temp_2 ? JSON.parse(temp_2) : {};

export const numeric = /^[0-9\b]+$/;
export const PROFILE_KEYS = {
  ADDRESS: 'address',
  EMAIL: 'email',
  PHONE: 'phone'
};

export const REACT_KEY = {
  REACT_API_KEY: 'QbJefbMFySOelwaACcnp6AgHxOLjjI4Zb9AxgUaDIwGMO6OBmrAabnMXRQbhZZDN',
  REACT_SECRET_KEY:
    'RSGK1PgSXxc0RCeJBsytz2D9DWltLd52mFSQnZx0IDWhE8IcbWew51IbR8EiSYo22igpIl5t4TO87l7AaUButhcNGPd2E0jumSUineIFpP5Hn1f6xbk6XuSLLnHwEVK0',
  EMAIL_KEY: '4544112244b2ddddefb9fdeb60ad0532',
  FCM_TOKEN_KEY: '82144112244b2ddddefb9fdeb60ad0532',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBrdj3xi32qprXJ3S8I8FNrwQKw8P2A8ww',
  REACT_APP_TEMP_USER_AUTH: '0544112244b2dddderdfb9fdeb60ad05322',
  REACT_APP_FIREBASE_VAPID_KEY:
    'BHpyLSxJehOnwFKLws1JPmmZlyQG_YRBLQdsoWljlAEOYCwsRDA2ttU5A9PaxXffCQyiHXupnCPy9g2sxi8LOlg',
  GOOGLE_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.silverbackmobile',
  APPLE_STORE_LINK: 'https://apps.apple.com/us/app/silverback-banking/id6477388491?platform=iphone'
};

export const INSURANCE = {
  WHOLE_LIFE: 'Whole Life',
  LONG_TERM: 'Long-Term Care',
  UMBRELLA: 'Umbrella',
  TERM_LIFE: 'Term Life',
  INDIVIDUAL_DISABILITY: 'Individual Disability',
  AUTO: 'Auto'
};

export const INSURANCET_TYPES = {
  WHOLE_LIFE: 1,
  LONG_TERM: 3,
  UMBRELLA: 6,
  TERM_LIFE: 2,
  INDIVIDUAL_DISABILITY: 4,
  AUTO: 5
};

export const ASSET = {
  INVESTMENT: 'investment',
  INSURANCE: 'insurance',
  REAL_ESTATE: 'real_estate',
  DEPOSITORY: 'depository',
  BANK: 'Bank',
  INCOME: 'income',
  OTHER1: 'Other',
  ASSET: 'asset',
  OTHER: 'other',
  INVESTMENT_NAME: 'Investment Name',
  ACCOUNT_NAME: 'Account Name',
  INVESTMENT_TYPE: 'Investment Type'
};

export const LIABILITIES = {
  OTHER1: 'Other',
  OTHER: 'other',
  LIABILITIES: 'liability',
  CREDIT: 'credit',
  CREDIT_CARD: 'Credit Card',
  LOAN: 'loan',
  CARD_NAME: 'Card Name',
  LOAN_NAME: 'Loan Name',
  CARD_TYPE: 'Card Type',
  ACCOUNT_TYPE: 'Account Type',
  LOAN_TYPE: 'Loan Type'
};

export const INCLUDE_IN_PLAN = [
  {
    id: true,
    description: 'Yes'
  },
  {
    id: false,
    description: 'No'
  }
];

export const ESTATE_PLANNING_KEYS = {
  POWER_OF_ATTORNEY: 'power_of_attorney',
  HIPAA_RELEASE_AND_AUTHORIZATION: 'hippa_release_and_authorization',
  LIVING_WILL_HEALTH_CARE_PROXY: 'living_will_health_care_proxy',
  LAST_WILL_AND_TESTAMENT: 'last_will_and_testament'
};

export const ESTATE_STATUS = {
  REVIEW: 'review',
  APPROVE: 'approve',
  REJECT: 'reject',
  SIGNATURE_PENDING: 'signature_pending'
};
export const TICKET_STATUS = {
  RESOLVED: 'resolved',
  PENDING: 'pending',
  REJECTED: 'rejected'
};
export const UPDATE_SIGNATURE_TYPE = {
  USER_PROFILE_SIGNATURE: 'user_profile_signature',
  ESTATE_FORM_SIGNATURE: 'estate_form_signature'
};
export const MANAGE_NOTIFICATION_STATUS = {
  WHATSAPP_NOTIFICATION: 'whatsappNotification',
  SMS_NOTIFICATION: 'smsNotification',
  PROMOTIONAL_PUSH_NOTIFICATION: 'promotionalPushNotification',
  PROMOTIONAL_EMAIL_NOTIFICATION: 'promotionalEmailNotification'
};
export const FUNDS_TYPES = {
  ACH_TYPE: 'NonVerifiedExternalUSFinancialBankAccount',
  EXTERNAL_TYPE: 'ExternalFinancialBankAccount',
  OTP_TYPE: 'APPROVE_TRANSACTION',
  CURRENCY_CODE: 840,
  INIT_TYPE: 'netevia'
};
export const USER_ACCOUNTS_TYPES = [
  {
    id: 'SAVINGS',
    name: 'SAVINGS'
  },
  {
    id: 'CHECKING',
    name: 'CHECKING'
  }
];

export const TRANSCATIONS_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  FAILED: 'FAILED'
};

export const CARDS_BRANDS = {
  NETEVIA: 'NETEVIA'
};
export const CARDS_TYPES = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL'
};

export const CARDS_ORDER_STATUS = {
  CANCEL: 'CANCELED',
  PLACED: 'NEW'
};

export const FEATURES_TYPES = {
  REWARD: 'reward',
  NETWORTH: 'net_worth',
  ESTATE: 'estate_planning',
  GOALS: 'goals',
  FINANCIALEXPERTFORM: 'financial_expert_form',
  BANKING: 'banking',
  GRAPH: 'graph',
  GIFT: 'gift',
  ACCOUNTSTATICS: 'account_statistics'
};
