import React from 'react';
import './index.css';
export default function SVButton(props) {
  const {
    style,
    onClick,
    title,
    height,
    background,
    color,
    width,
    className,
    isLoading,
    fontSize,
    disabled,
    fullWidth,
    ref
  } = props;
  return (
    <div style={{ display: fullWidth ? 'contents' : '' }}>
      <button
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        style={{
          ...style,
          borderRadius: 30,
          width: width ? width : '100%',
          height: height ? height : '45px',
          fontWeight: 600,
          fontSize: fontSize ? fontSize : '0.8rem',
          letterSpacing: 1.5,
          background: disabled ? '#707070' : background ? background : '#0887fc',
          color: color ? color : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        type="submit"
        className={className ? className : 'btn btn-lg'}>
        {isLoading ? <div className="loader"></div> : <div className="fs-6">{title}</div>}
      </button>
    </div>
  );
}
