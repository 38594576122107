import { createSlice } from '@reduxjs/toolkit';
import { errorToast, successToast } from 'component/toast';
import api from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';

const slice = createSlice({
  name: 'networth',
  initialState: {
    insuranceData: [],
    insurancePeriodData: [],
    cashValueApproachesData: [],
    distributionsData: [],
    deathBenefitOptionsData: [],
    benefitPeriodData: [],
    insuranceYearsData: [],
    taxesListData: [],
    inflationTypesData: [],
    eliminationPeriodsData: [],
    benefitsTypesData: [],
    insuranceDetails: null
  },
  reducers: {
    getInsuranceTypesSuccess: (state, action) => {
      state.insuranceData = action.payload;
    },
    getInsurancePeriodSuccess: (state, action) => {
      state.insurancePeriodData = action.payload;
    },
    getCashValueApproachesSuccess: (state, action) => {
      state.cashValueApproachesData = action.payload;
    },
    getDistributionsListSuccess: (state, action) => {
      state.distributionsData = action.payload;
    },
    getDeathBenefitsListSuccess: (state, action) => {
      state.deathBenefitOptionsData = action.payload;
    },
    getBenefitPeriodsSuccess: (state, action) => {
      state.benefitPeriodData = action.payload;
    },
    getInsuranceYearSuccess: (state, action) => {
      state.insuranceYearsData = action.payload;
    },
    getTaxesListSuccess: (state, action) => {
      state.taxesListData = action.payload;
    },
    getInflationTypesSuccess: (state, action) => {
      state.inflationTypesData = action.payload;
    },
    getEliminationPeriodSuccess: (state, action) => {
      state.eliminationPeriodsData = action.payload;
    },
    getBenefitsTypesSuccess: (state, action) => {
      state.benefitsTypesData = action.payload;
    },
    getInsuranceDetailsSuccess: (state, action) => {
      state.insuranceDetails = action.payload.data.db_insurance;
    },
    clearInsuranceDetailsSuccess: (state, action) => {
      state.insuranceDetails = action.payload;
    }
  }
});

const {
  getInsuranceTypesSuccess,
  getInsurancePeriodSuccess,
  getCashValueApproachesSuccess,
  getDistributionsListSuccess,
  getDeathBenefitsListSuccess,
  getBenefitPeriodsSuccess,
  getInsuranceYearSuccess,
  getTaxesListSuccess,
  getInflationTypesSuccess,
  getEliminationPeriodSuccess,
  getBenefitsTypesSuccess,
  getInsuranceDetailsSuccess,
  clearInsuranceDetailsSuccess
} = slice.actions;

export default slice.reducer;

/**********************ACTIONS************************************************ */

// financial advice form

export const financialAdviceRequest = (data, navigate) => async () => {
  try {
    await api.post(SILVERBACK_API.networth.financialAdviceRequest, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        navigate('/customer/net-worth');
        successToast(response.data.message, 'support');
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get insurance types list

export const getInsuranceTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getInsuranceTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsuranceTypesSuccess(response?.data?.data?.types));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get insurance period list
export const getInsurancePeriod = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getInsurancePeriod).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsurancePeriodSuccess(response?.data?.data?.periods));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// create insurance

export const createInsurance = (request, navigate, setIsloading) => async () => {
  try {
    await api.post(SILVERBACK_API.insurance.createInsurance, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message, 'support');
        navigate('/customer/net-worth/insurance');
      } else {
        errorToast(response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Cash Value Approaches

export const getCashValueApproaches = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getCashValueApproaches).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getCashValueApproachesSuccess(response?.data?.data?.cashvalueapproaches));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Distributions List

export const getDistributionsList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getDistributionsList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDistributionsListSuccess(response?.data?.data?.distributions));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Death Benefits List

export const getDeathBenefitsList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getDeathBenefitsList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDeathBenefitsListSuccess(response?.data?.data?.deathBenefits));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Benefit Periods

export const getBenefitPeriods = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getBenefitPeriods).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getBenefitPeriodsSuccess(response?.data?.data?.benefitperiods));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//   get Insurance Year

export const getInsuranceYear = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getInsuranceYear).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsuranceYearSuccess(response?.data?.data?.years));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Taxes List

export const getTaxesList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getTaxesList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getTaxesListSuccess(response?.data?.data?.taxes));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Inflation Types

export const getInflationTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getInflationTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInflationTypesSuccess(response?.data?.data?.inflation_types));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Elimination Period

export const getEliminationPeriod = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getEliminationPeriod).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getEliminationPeriodSuccess(response?.data?.data?.elimination_periods));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Benefits Types

export const getBenefitsTypes = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.insurance.getBenefitsTypes).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getBenefitsTypesSuccess(response?.data?.data?.benefit_types));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Insurance Details

export const getInsuranceDetails = (id, setIsloading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.insurance.getInsuranceDetails}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getInsuranceDetailsSuccess(response?.data));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// clear insurance details
export const clearInsuranceDetails = () => async (dispatch) => {
  dispatch(clearInsuranceDetailsSuccess(null));
};
// update Insurance
export const updateInsurance = (id, request, navigate, setIsloading) => async () => {
  try {
    await api.put(`${SILVERBACK_API.insurance.updateInsurance}/${id}`, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message, 'support');
        navigate('/customer/net-worth/asset-detail/insurance');
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
