/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { errorToast, successToast } from 'component/toast';
import api from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';

const slice = createSlice({
  name: 'funds',
  initialState: {
    externalAccountsData: [],
    payeeListData: [],
    financialAccounts: [],
    transcationListData: null,
    transcationDetails: null,
    bannerList: null,
    pressReleaseDetail: null,
    pressListData: null
  },
  reducers: {
    getExternalAccountsSuccess: (state, action) => {
      state.externalAccountsData = action.payload?.data?.data;
    },
    deleteExternalAccountsSuccess: (state, action) => {
      state.externalAccountsData = state.externalAccountsData.filter(
        (item) => item.id != action.payload
      );
    },
    getPayeesListSuccess: (state, action) => {
      state.payeeListData = action.payload?.data?.data?.payees;
    },
    deletePayeeSuccess: (state, action) => {
      state.payeeListData = state.payeeListData.filter((item) => item.id != action.payload);
    },
    getfinancialAccountsDetailsSuccess: (state, action) => {
      state.financialAccounts = action.payload;
    },
    getTranscationlistSuccess: (state, action) => {
      state.transcationListData = action.payload.data;
    },
    clearTranscationlistSuccess: (state, action) => {
      state.transcationListData = null;
    },
    getTranscationDetailsSuccess: (state, action) => {
      state.transcationDetails = action.payload;
    },
    clearTranscationDetailSuccess: (state, action) => {
      state.transcationDetails = null;
    },
    getBannerListSuccess: (state, action) => {
      state.bannerList = action.payload;
    },
    getPressReleaseListSuccess: (state, action) => {
      state.pressListData = action.payload;
    },
    getPressReleaseDetailsSuccess: (state, action) => {
      state.pressReleaseDetail = action.payload;
    },
    clearPressReleaseDetailsSuccess: (state, action) => {
      state.pressReleaseDetail = action.payload;
    }
  }
});

const {
  getExternalAccountsSuccess,
  deleteExternalAccountsSuccess,
  getPayeesListSuccess,
  deletePayeeSuccess,
  getfinancialAccountsDetailsSuccess,
  getTranscationlistSuccess,
  clearTranscationlistSuccess,
  getTranscationDetailsSuccess,
  clearTranscationDetailSuccess,
  getBannerListSuccess,
  getPressReleaseListSuccess,
  getPressReleaseDetailsSuccess,
  clearPressReleaseDetailsSuccess
} = slice.actions;

/**********************ACTIONS************************************************ */

// get External Accounts
export const getExternalAccounts = (type, setIsloading) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK_API.funds.getExternalAccounts}?typename=${type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getExternalAccountsSuccess(response.data));
        } else {
          errorToast(response.data.detail, 'states');
        }
        if (setIsloading) {
          setIsloading(false);
        }
      });
  } catch (e) {
    if (setIsloading) {
      setIsloading(false);
    }
    return console.error(e.message);
  }
};

//  get verification otp for funds
export const getFundsOtp = (data, setIsloading, setFormData) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.funds.getFundsOtp, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        setFormData((formData) => ({
          ...formData,
          showOtpScreen: true
        }));
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    });
  } catch (e) {
    setIsloading(false);
    errorToast(e?.response?.data?.detail);
    return console.error(e.message);
  }
};

// add External Accounts

export const addExternalAccounts = (data, setIsloading, handleClose) => async () => {
  api
    .post(SILVERBACK_API.funds.addExternalAccounts, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        handleClose();
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};

// delete External Accounts
export const deleteExternalAccounts =
  (id, setIsloading, setShowDeleteDialog) => async (dispatch) => {
    api
      .delete(`${SILVERBACK_API.funds.deleteExternalAccounts}/${id}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(deleteExternalAccountsSuccess(id));
          successToast(response?.data?.message);
          setShowDeleteDialog(false);
        } else {
          errorToast(response.data.detail, 'states');
        }
        setIsloading(false);
      })
      .catch((e) => {
        errorToast(e?.response?.data?.detail, 'support');
        setIsloading(false);
      });
  };

// get External Accounts
export const getPayeesList = (setIsloading) => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.funds.getPayeesList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getPayeesListSuccess(response.data));
      } else {
        errorToast(response.data.detail, 'states');
      }
      if (setIsloading) {
        setIsloading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// delete External Accounts
export const deletePayee = (data, setIsloading, setShowDeleteDialog) => async (dispatch) => {
  api
    .post(SILVERBACK_API.funds.deletePayee, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deletePayeeSuccess(data?.financialAccountId));
        successToast(response?.data?.message);
        setShowDeleteDialog(false);
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};
// get External Accounts
export const getAccountInfo = (info, setIsloading, setFormData, handleGetOtp) => async () => {
  api
    .get(`${SILVERBACK_API.funds.getAccountInfo}/${info}`)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        setFormData((formData) => ({
          ...formData,

          accountNumber: response?.data?.data?.data?.accountNumber,
          name: response?.data?.data?.data?.name
        }));
        if (handleGetOtp) {
          handleGetOtp();
        }
      } else {
        errorToast(response.data.detail, 'states');
        setIsloading(false);
      }
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};

// add Payee

export const addPayee = (data, setIsloading, handleClose) => async () => {
  api
    .post(SILVERBACK_API.funds.addPayee, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        handleClose();
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};

//  get financial Accounts Details
export const getfinancialAccountsDetails = (setIsloading) => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.funds.getfinancialAccountsDetails).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getfinancialAccountsDetailsSuccess(response?.data?.data?.data?.financialAccounts));
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// ach Transfer Money

export const achTransferMoney = (data, setIsloading, handleClose) => async () => {
  api
    .post(SILVERBACK_API.funds.achTransferMoney, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        handleClose();
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};

// add Funds

export const addFunds = (data, setIsloading, handleClose) => async () => {
  api
    .post(SILVERBACK_API.funds.addFunds, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        handleClose();
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};

//   get Transcation list
export const getTranscationlist = (data, setIsloading, setLoadMoreLoader) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.funds.getTranscationlist, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getTranscationlistSuccess(response?.data));
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
      if (setLoadMoreLoader) {
        setLoadMoreLoader(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const clearTranscationlist = () => async (dispatch) => {
  dispatch(clearTranscationlistSuccess(null));
};

// dispute Transcation

export const disputeTranscation = (data, setIsloading, handleClose) => async () => {
  api
    .post(SILVERBACK_API.funds.disputeTranscation, data)
    .then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        handleClose();
      } else {
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail, 'support');
      setIsloading(false);
    });
};
// get Transcation Details
export const getTranscationDetails = (id, setIsloading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.funds.getTranscationDetails}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getTranscationDetailsSuccess(response?.data?.data?.data?.node));
      } else {
        errorToast(response.data.detail);
      }
      setIsloading(false);
    });
  } catch (e) {
    setIsloading(false);
    errorToast(e?.response.data.detail);
    return console.error(e.message);
  }
};

// get Banner List
export const getBannerList = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.getBannerList, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getBannerListSuccess(response?.data?.data));
      } else {
        errorToast(response.data.detail, 'states');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// clear Transcation Detail

export const clearTranscationDetail = () => async (dispatch) => {
  dispatch(clearTranscationDetailSuccess(null));
};

// getAccountBalance
export const getAccountBalance = (id, setBalanceLoader) => async () => {
  try {
    await api.get(`${SILVERBACK_API.funds.getAccountBalance}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        console.log('rrr', response.data);
      } else {
        errorToast(response.data.detail, 'states');
      }
      setBalanceLoader(false);
    });
  } catch (e) {
    setBalanceLoader(false);
    return console.error(e.message);
  }
};

// getPressReleaseList
export const getPressReleaseList = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.getPressReleaseList, data).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getPressReleaseListSuccess(response.data.data));
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get PressRelease Details
export const getPressReleaseDetails = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.getPressReleaseDetails, data).then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getPressReleaseDetailsSuccess(response.data.data));
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// clear PressRelease Details
export const clearPressReleaseDetails = () => async (dispatch) => {
  dispatch(clearPressReleaseDetailsSuccess(null));
};

export default slice.reducer;
