/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';
import { errorToast, successToast } from 'component/toast';
import { REACT_KEY } from 'utils/appConstant';
import secureLocalStorage from 'react-secure-storage';
import Cookies from 'js-cookie';

const slice = createSlice({
  name: 'profile',
  initialState: {
    stateListData: null,
    accountListData: null,
    profileDetail: null,
    notificationData: null,
    atmListData: null,
    assetsList: null,
    getToken: null,
    incomeListData: null,
    realEstateListData: null,
    accountData: null,
    otherTypeSuccess: null,
    liabilitiesListData: null,
    otherTypeList: null,
    otherTypeData: null,
    goalDetailData: null,
    getGoalListData: null,
    iconListData: null,
    addGoalData: null,
    goalDetail: null,
    updateGoalData: null,
    udateAmountData: null,
    getTokenIncome: null,
    netWorthSummaryData: [],
    assetStatisticsDetail: [],
    incomeStatisticsData: [],
    realEstateStatisticsData: [],
    otherTypeStatisticsData: [],
    depositoryData: [],
    insuranceDetailTypeData: [],
    investmentTypeDetail: [],
    liabilityStatisticsDetail: [],
    creditData: [],
    loanData: [],
    ticketListData: null,
    ticketCategoryList: [],
    ticketSubCategoryList: [],
    myBankAccountData: [],
    documentsList: null,
    siteSettingData: {},
    showFeatureListData: []
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    //For listing

    stateListSuccess: (state, action) => {
      state.stateListData = action.payload.data;
    },
    getinitSuccess: (state, action) => {
      state.getToken = action.payload.data;
    },
    getinitIncomeSuccess: (state, action) => {
      state.getTokenIncome = action.payload.data;
    },
    netWorthSummarySuccess: (state, action) => {
      state.netWorthSummaryData = action.payload.data;
    },
    stateListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountListSuccess: (state, action) => {
      state.accountListData = action.payload.data;
    },
    accountListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    addOtherAssetSuccess: (state, action) => {
      state.otherTypeData = action.payload.data;
    },
    addOtherAssetFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    getProfileDetailSuccess: (state, action) => {
      state.profileDetail = action.payload.data.response_data;
    },
    updateProfilePictureSuccess: (state, action) => {
      state.profileDetail.fullPath = action.payload.fullPath;
      state.profileDetail.path = action.payload.path;
    },
    getNotificationListSuccess: (state, action) => {
      state.notificationData = action.payload;
    },
    atmLocatorData: (state, action) => {
      state.atmListData = action.payload;
    },
    assetsSuccess: (state, action) => {
      state.assetsList = action.payload.data;
    },
    assetsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    getGoalDetailsSuccess: (state, action) => {
      state.goalDetailData = action.payload.data;
    },
    getGoalDetailsFailded: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    goalDetailApiSuccess: (state, action) => {
      state.goalDetail = action.payload.data;
    },
    clearGoalDetailsSuccess: (state, action) => {
      state.goalDetail = action.payload;
    },
    goalDetailApiFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    getGoalListSuccess: (state, action) => {
      state.getGoalListData = action.payload.data;
    },
    getGoalListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    iconListSuccess: (state, action) => {
      state.iconListData = action.payload.data;
    },
    iconListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    addGoalsSuccess: (state, action) => {
      state.addGoalData = action.payload.data;
    },
    addGoalsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateGoalsSuccess: (state, action) => {
      state.updateGoalData = action.payload.data;
    },
    updateGoalsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateAmountApiSuccess: (state, action) => {
      state.udateAmountData = action.payload.data;
    },
    updateAmountApiFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    LiabilitiesSuccess: (state, action) => {
      state.liabilitiesListData = action.payload.data;
    },
    LiabilitiesFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    incomeListSuccess: (state, action) => {
      state.incomeListData = action.payload.data.items;
    },
    incomeListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    realEstateListSuccess: (state, action) => {
      state.realEstateListData = action.payload.data.details;
    },
    realEstateListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    insuranceListSuccess: (state, action) => {
      state.insuranceDetailData = action.payload.data.insurances_data;
    },
    insuranceListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    accountTypeSuccess: (state, action) => {
      state.accountData = action.payload.data;
    },
    accountTypeFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    getOtherTypeListSuccess: (state, action) => {
      state.otherTypeList = action.payload.data.assetDebtData;
    },
    getOtherTypeListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    otherTypeSuccess: (state, action) => {
      state.otherDetailData = action.payload.data;
    },
    otherTypeFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    deleteGoalSuccess: (state, action) => {
      state.getGoalListData = state.getGoalListData.filter((item) => item.id != action.payload);
      state.isLoading = false;
    },
    deleteRealEstateSuccess: (state, action) => {
      state.realEstateListData = state.realEstateListData.filter(
        (item) => item.id != action.payload
      );
    },
    deleteOtherAssestSuccess: (state, action) => {
      state.otherDetailData = state.otherDetailData.filter((item) => item.id != action.payload);
    },

    deleteInsuranceSuccess: (state, action) => {
      state.insuranceDetailData = state.insuranceDetailData.filter(
        (item) => item.insurance_id != action.payload
      );
    },
    assetStatisticsSuccess: (state, action) => {
      state.assetStatisticsDetail = action.payload.data;
    },
    assetStatisticsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    liabilityStatisticsSuccess: (state, action) => {
      state.liabilityStatisticsDetail = action.payload.data;
    },
    liabilityStatisticsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    getIncomeStatisticsSuccess: (state, action) => {
      state.incomeStatisticsData = action.payload.data;
    },
    getIncomeStatisticsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    getRealEstateStatisticsSuccess: (state, action) => {
      state.realEstateStatisticsData = action.payload.data;
    },
    getRealEstateStatisticsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },

    otherTypeStatisticsSuccess: (state, action) => {
      state.otherTypeStatisticsData = action.payload.data;
    },
    otherTypeStatisticsFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountStatisticsDepositorySuccess: (state, action) => {
      state.depositoryData = action.payload.data;
    },
    accountStatisticsDepositoryFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountStatisticsCreditSuccess: (state, action) => {
      state.creditData = action.payload.data;
    },
    accountStatisticsCreditFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountStatisticsLoanSuccess: (state, action) => {
      state.loanData = action.payload.data;
    },
    accountStatisticsLoanFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountStatisticsInsuranceTypeSuccess: (state, action) => {
      state.insuranceDetailTypeData = action.payload.data;
    },
    accountStatisticsInsuranceTypeFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    accountStatisticsInvestmentSuccess: (state, action) => {
      state.investmentTypeDetail = action.payload.data;
    },
    accountStatisticsInvestmentFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    clearAssestDetailDataSuccess: (state, action) => {
      state.incomeListData = action.payload;
      state.realEstateListData = action.payload;
      state.insuranceDetailData = action.payload;
      state.accountData = action.payload;
      state.otherDetailData = action.payload;
    },
    getTicketsListSuccess: (state, action) => {
      state.ticketListData = action.payload;
    },
    getTicketsCategoryListSuccess: (state, action) => {
      state.ticketCategoryList = action.payload;
    },
    getTicketsSubcategoriesListSuccess: (state, action) => {
      state.ticketSubCategoryList = action.payload;
    },
    getMyBankAccountSuccess: (state, action) => {
      state.myBankAccountData = action.payload;
    },
    getDocumentsListSuccess: (state, action) => {
      state.documentsList = action.payload;
    },
    readNotificationSuccess: (state, action) => {
      let objIndex = state.notificationData?.notificationData?.findIndex(
        (item) => item.id == action.payload
      );
      state.notificationData.notificationData[objIndex].isRead = true;
      state.notificationData.unReadCount = state.notificationData.unReadCount - 1;
    },
    getFeaturesListSuccess: (state, action) => {
      state.showFeatureListData = action.payload;
    }
  }
});

// ** Actions **
const {
  getFeaturesListSuccess,
  readNotificationSuccess,
  getMyBankAccountSuccess,
  getTicketsSubcategoriesListSuccess,
  getTicketsCategoryListSuccess,
  getTicketsListSuccess,
  clearGoalDetailsSuccess,
  deleteOtherAssestSuccess,
  deleteRealEstateSuccess,
  clearAssestDetailDataSuccess,
  deleteInsuranceSuccess,
  stateListSuccess,
  stateListFailed,
  accountListSuccess,
  loadingSuccess,
  getProfileDetailSuccess,
  updateProfilePictureSuccess,
  getNotificationListSuccess,
  atmLocatorData,
  assetsSuccess,
  assetsFailed,
  getinitSuccess,
  incomeListSuccess,
  incomeListFailed,
  realEstateListSuccess,
  realEstateListFailed,
  insuranceListSuccess,
  insuranceListFailed,
  accountTypeSuccess,
  accountTypeFailed,
  otherTypeFailed,
  otherTypeSuccess,
  LiabilitiesSuccess,
  LiabilitiesFailed,
  getOtherTypeListSuccess,
  getOtherTypeListFailed,
  addOtherAssetSuccess,
  addOtherAssetFailed,
  getGoalDetailsSuccess,
  getGoalDetailsFailded,
  getGoalListSuccess,
  getGoalListFailed,
  iconListSuccess,
  iconListFailed,
  addGoalsSuccess,
  addGoalsFailed,
  deleteGoalSuccess,
  deleteGoalFailed,
  goalDetailApiSuccess,
  goalDetailApiFailed,
  updateGoalsSuccess,
  updateGoalsFailed,
  updateAmountApiSuccess,
  updateAmountApiFailed,
  getinitIncomeSuccess,
  netWorthSummarySuccess,
  assetStatisticsSuccess,
  assetStatisticsFailed,
  getIncomeStatisticsSuccess,
  getIncomeStatisticsFailed,
  getRealEstateStatisticsSuccess,
  getRealEstateStatisticsFailed,
  otherTypeStatisticsSuccess,
  otherTypeStatisticsFailed,
  accountStatisticsDepositorySuccess,
  accountStatisticsDepositoryFailed,
  accountStatisticsInsuranceTypeSuccess,
  accountStatisticsInsuranceTypeFailed,
  accountStatisticsInvestmentSuccess,
  accountStatisticsInvestmentFailed,
  liabilityStatisticsSuccess,
  liabilityStatisticsFailed,
  accountStatisticsCreditSuccess,
  accountStatisticsCreditFailed,
  accountStatisticsLoanSuccess,
  accountStatisticsLoanFailed,
  getDocumentsListSuccess
} = slice.actions;

export const stateList = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK_API.stateList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(stateListSuccess(response.data));
      } else {
        dispatch(stateListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addOtherAsset = (data, navigate, setIsLoading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.addOtherTypeURl, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(addOtherAssetSuccess(response.data));
        if (data?.type === 'asset') {
          navigate('/customer/net-worth/asset-detail/other');
        } else {
          navigate('/customer/net-worth/liabilities-detail/other');
        }
      } else {
        dispatch(addOtherAssetFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      if (setIsLoading) {
        setIsLoading(false);
      }

      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAssetStatistics = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api.get(SILVERBACK_API.profile.getAssetStatisticsURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(assetStatisticsSuccess(response.data));
      } else {
        dispatch(assetStatisticsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getLiabilityStatistics = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api.get(SILVERBACK_API.profile.getLiabilityStatisticsURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(liabilityStatisticsSuccess(response.data));
      } else {
        dispatch(liabilityStatisticsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountStatisticDepositoryType = (data) => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api
      .get(`${SILVERBACK_API.profile.getAccountStatisticTypeURL}/${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountStatisticsDepositorySuccess(response.data));
        } else {
          dispatch(accountStatisticsDepositoryFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountStatisticCreditType = (data) => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api
      .get(`${SILVERBACK_API.profile.getAccountStatisticLiabilityURL}/${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountStatisticsCreditSuccess(response.data));
        } else {
          dispatch(accountStatisticsCreditFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountStatisticLoanType = (data) => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api
      .get(`${SILVERBACK_API.profile.getAccountStatisticLiabilityURL}/${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountStatisticsLoanSuccess(response.data));
        } else {
          dispatch(accountStatisticsLoanFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountStatisticInvestmentType = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK_API.profile.getAccountStatisticTypeURL}/${data?.investmentType}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountStatisticsInvestmentSuccess(response.data));
        } else {
          dispatch(accountStatisticsInvestmentFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountStatisticInsuranceType = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK_API.profile.getAccountStatisticTypeURL}/${data?.insuranceType}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountStatisticsInsuranceTypeSuccess(response.data));
        } else {
          dispatch(accountStatisticsInsuranceTypeFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getIncomeStatistics = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api.get(SILVERBACK_API.profile.getIncomeStatisticsURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getIncomeStatisticsSuccess(response.data));
      } else {
        dispatch(getIncomeStatisticsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getRealEstateStatistics = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));getAssetStatistics
  try {
    await api.get(SILVERBACK_API.profile.realEstateURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getRealEstateStatisticsSuccess(response.data));
      } else {
        dispatch(getRealEstateStatisticsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLiabilities = (setIsloading) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.get(SILVERBACK_API.profile.liabilitiesListURl).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(LiabilitiesSuccess(response.data));
      } else {
        dispatch(LiabilitiesFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      setIsloading(false);
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAssets = (setIsloading) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.get(SILVERBACK_API.profile.assetsList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(assetsSuccess(response.data));
      } else {
        dispatch(assetsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      if (setIsloading) {
        setIsloading(false);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//income List

export const getIncomeList = (setIsloading) => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.profile.getIncomeListURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(incomeListSuccess(response.data));
      } else {
        dispatch(incomeListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
      if (setIsloading) {
        setIsloading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//realEstate List

export const realEstateList = (data, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.realEstateListURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(realEstateListSuccess(response.data));
      } else {
        dispatch(realEstateListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
      if (setIsloading) {
        setIsloading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//insurance List

export const insuranceList = (data, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.insuranceListURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(insuranceListSuccess(response.data));
      } else {
        dispatch(insuranceListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      if (setIsloading) {
        setIsloading(false);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOtherTypeList = (data) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.profile.otherTypeListURl}/${data?.type}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getOtherTypeListSuccess(response.data));
      } else {
        dispatch(getOtherTypeListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const otherTypeStatistics = (data) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK_API.profile.otherTypeStatisticsURL}/type?type=${data?.type}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(otherTypeStatisticsSuccess(response.data));
        } else {
          dispatch(otherTypeStatisticsFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};
//accoutn type api List

export const accountType = (data, setIsloading) => async (dispatch) => {
  try {
    await api
      .get(`${SILVERBACK_API.profile.accountURL}/${data?.type}/${data?.subtype}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(accountTypeSuccess(response.data));
        } else {
          dispatch(accountTypeFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        if (setIsloading) {
          setIsloading(false);
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};
//ohter type api
export const otherType = (data, setIsloading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.profile.otherTypeURl}/${data?.type}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(otherTypeSuccess(response.data));
      } else {
        dispatch(otherTypeFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      if (setIsloading) {
        setIsloading(false);
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getinit = (provide) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api
      .get(
        `${SILVERBACK_API.profile.getInitURL}?platform=ANDROID&provider=${provide ? provide : ''}`
      )
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getinitSuccess(response.data));
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getinitIncome = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api
      .get(`${SILVERBACK_API.profile.getInitIncomeURL}?platform=WEBSITE`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(getinitIncomeSuccess(response.data));
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getNetWorthSummary = (setIsloading) => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.get(`${SILVERBACK_API.profile.worthSummaryURL}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(netWorthSummarySuccess(response.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
      if (setIsloading) {
        setIsloading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const financeAccountList = () => async (dispatch) => {
  // dispatch(loadingSuccess(true));
  try {
    await api.get(SILVERBACK_API.financeAccountList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(accountListSuccess(response.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
//  get profile details
export const getProfileDetail = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.profile.getProfileDetail).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getProfileDetailSuccess(response.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//  update profile picture
export const updateProfilePicture = (file) => async (dispatch) => {
  const formData = new FormData();
  formData.append('folderName', 'profilePic');
  formData.append('file', file);
  try {
    await api
      .post(SILVERBACK_API.profile.updateProfilePicture, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(updateProfilePictureSuccess(response.data));
        } else {
          errorToast(response.data.detail, 'account');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

//  site -setting
export const siteSetting = () => async (dispatch) => {
  try {
    await api
      .get(SILVERBACK_API.siteSetting, {
        headers: {
          'api-key': REACT_KEY.REACT_API_KEY,
          'secret-key': REACT_KEY.REACT_SECRET_KEY
        }
      })
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          Cookies.set('set_temp_site_setting_data', JSON.stringify(response.data));
          secureLocalStorage.setItem('siteSettingData', JSON.stringify(response.data));
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
//  update-profile
export const updateProfile = (data, onClose, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.updateProfile, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        dispatch(getProfileDetail());
        if (onClose) {
          onClose();
        }
      } else {
        errorToast(response.data.detail, 'account');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//  send-otp-to-update-profile
export const getOtpForProfile = (data, setStepper, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.getOtp, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        if (setStepper) {
          setStepper(2);
        }
      } else {
        errorToast(response.data.detail);
      }
      setIsloading(false);
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    setIsloading(false);
    errorToast(e?.response?.data?.detail);
    return console.error(e.message);
  }
};

//  verify-otp-to-update-profile
export const verifyOtpForProfile =
  (data, updatProfileRequest, setStepper, onClose, setIsloading) => async (dispatch) => {
    try {
      await api.post(SILVERBACK_API.profile.verifyOtp, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(updateProfile(updatProfileRequest));
          setStepper(1);
          onClose();
        } else {
          errorToast(response.data.detail, 'account');
        }
        setIsloading(false);
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
// upload document
export const uploaDocumentForAddress =
  (data, setDocument, setDocumentImages) => async (dispatch) => {
    try {
      await api
        .post(SILVERBACK_API.networth.uploaDocument, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.status === STATUS_CODES.SUCCESS) {
            setDocument((document) => [...document, response.data.data.documentId]);
            setDocumentImages((document) => [...document, response.data.data.fullPath]);
          } else {
            errorToast(response.data.detail, 'account');
          }

          dispatch(loadingSuccess(false));
        });
    } catch (e) {
      return console.error(e.message);
    }
  };
// get all notification list
export const getNotificationList = (data, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.getNotificationList, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getNotificationListSuccess(response.data.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
      if (setIsloading) {
        setIsloading(false);
      }
    });
  } catch (e) {
    if (setIsloading) {
      setIsloading(false);
    }
    return console.error(e.message);
  }
};

// read notification

export const readNotification = (data, handleReadAllNotificationSuccess) => async (dispatch) => {
  api
    .post(SILVERBACK_API.readNotification, data)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        if (!data?.readAll) {
          dispatch(readNotificationSuccess(data?.notificationId));
        } else {
          handleReadAllNotificationSuccess();
        }
      } else {
        errorToast(response?.data?.detail);
      }
    })
    .catch((e) => {
      errorToast(e?.response?.data?.detail);
      return errorToast(e?.response?.data?.detail, 'support');
    });
};
//  atm locator
export const atmLocator = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.atmLocator, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(atmLocatorData(response.data.data.atms.node.atmLocations.atmLocations));
      } else {
        errorToast(response.data.detail, 'account');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//goal Detail

export const getGoalDetails = (navigate) => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.profile.goalDetailURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getGoalDetailsSuccess(response.data));
        if (navigate) {
          navigate('/customer/net-worth/goals');
        }
      } else {
        dispatch(getGoalDetailsFailded(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
//goal Detail

export const goalDetailApi = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK_API.profile.goalDetailApiURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(goalDetailApiSuccess(response.data));
      } else {
        dispatch(goalDetailApiFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//  clear goal detail
export const clearGoalDetails = () => async (dispatch) => {
  dispatch(clearGoalDetailsSuccess(null));
};
//goal List

export const getGoalList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.profile.goalListURL).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getGoalListSuccess(response.data));
      } else {
        dispatch(getGoalListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

//delete goal

export const deleteGoal = (data, setConfirmDialog) => async (dispatch) => {
  try {
    await api
      .delete(`${SILVERBACK_API.profile.deleteGoalURL}/${data?.goal_id}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(deleteGoalSuccess(data?.goal_id));
          setConfirmDialog(false);
          successToast(response.data.message);
        } else {
          dispatch(deleteGoalFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const iconList = (data) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.iconListURl, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(iconListSuccess(response.data));
      } else {
        dispatch(iconListFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const addGoals = (data, navigate, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK_API.profile.addGoalsURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(addGoalsSuccess(response.data));
        dispatch(getGoalDetails(navigate));

        successToast(response?.data?.message);
      } else {
        dispatch(addGoalsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateGoals = (data, navigate, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));

  try {
    await api.post(SILVERBACK_API.profile.updateGoalURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getGoalDetails(navigate));
        dispatch(updateGoalsSuccess(response.data));
        successToast(response?.data?.message);
      } else {
        dispatch(updateGoalsFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateAmountApi =
  (data, setAddPaymentDailog, fetchGoalDetail, setAmount) => async (dispatch) => {
    try {
      await api.post(SILVERBACK_API.profile.updateAmountApiURl, data).then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          dispatch(updateAmountApiSuccess(response.data));
          dispatch(getGoalDetails());
          successToast(response.data.message);
          setAddPaymentDailog(false);
          fetchGoalDetail();
          setAmount('');
        } else {
          dispatch(updateAmountApiFailed(response.error));
          errorToast(response.data.detail, 'states');
        }
        dispatch(loadingSuccess(false));
      });
    } catch (e) {
      errorToast(e?.response?.data?.detail);
      return console.error(e.message);
    }
  };

// delete Insurance

export const deleteInsurance = (id, setShowDeleteDialog) => async (dispatch) => {
  try {
    await api.delete(`${SILVERBACK_API.insurance.deleteInsurance}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deleteInsuranceSuccess(id));
        successToast(response?.data?.message);
      } else {
        errorToast(response.data.detail, 'account');
      }
      setShowDeleteDialog(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// delete real estate
export const deleteRealEstate = (id, setShowDeleteDialog, setIsloading) => async (dispatch) => {
  try {
    await api.delete(`${SILVERBACK_API.insurance.deleteRealEstate}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deleteRealEstateSuccess(id));
        successToast(response?.data?.message);
      } else {
        errorToast(response.data.detail, 'account');
      }
      setIsloading(false);
      setShowDeleteDialog(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// delete other assest
export const deleteOtherAssest = (id, setShowDeleteDialog, setIsloading) => async (dispatch) => {
  try {
    await api.delete(`${SILVERBACK_API.insurance.deleteOtherAssest}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(deleteOtherAssestSuccess(id));
        successToast(response?.data?.message);
      } else {
        errorToast(response.data.detail, 'account');
      }
      setIsloading(false);
      setShowDeleteDialog(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
//  clear assest detail data

export const clearAssestDetailData = () => async (dispatch) => {
  dispatch(clearAssestDetailDataSuccess(null));
};

//  get ticket list
export const getTicketsList = (data, setIsloading) => async (dispatch) => {
  api
    .post(SILVERBACK_API.getTicketsList, data)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getTicketsListSuccess(response?.data?.data));
      } else {
        errorToast(response?.data?.detail, 'support');
      }
      setIsloading(false);
    })
    .catch((e) => {
      setIsloading(false);
      return errorToast(e?.response?.data?.detail, 'support');
    });
};

//  get ticket category list
export const getTicketsCategoryList = (data, setIsloading) => async (dispatch) => {
  api
    .get(SILVERBACK_API.getTicketsCategoryList, data)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getTicketsCategoryListSuccess(response?.data?.data?.finalData));
      } else {
        errorToast(response?.data?.detail, 'support');
      }
      setIsloading(false);
    })
    .catch((e) => {
      return errorToast(e?.response?.data?.detail, 'support');
    });
};
//  get ticket subcategory list
export const getTicketsSubcategoriesList = (id) => async (dispatch) => {
  api
    .get(`${SILVERBACK_API.getTicketsSubcategoriesList}/${id}`)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        dispatch(getTicketsSubcategoriesListSuccess(response?.data?.data?.finalData));
      } else {
        errorToast(response?.data?.detail, 'support');
      }
    })
    .catch((e) => {
      return errorToast(e?.response?.data?.detail, 'support');
    });
};

// raise Ticket
export const raiseTicket = (data, fetchTicketsList, setFormData, handleClose) => async () => {
  api
    .post(SILVERBACK_API.raiseTicket, data)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        fetchTicketsList();
        setFormData({});
        handleClose();
      } else {
        errorToast(response?.data?.detail, 'support');
      }
    })
    .catch((e) => {
      return errorToast(e?.response?.data?.detail, 'support');
    });
};

// get MyBank Account
export const getMyBankAccount = () => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.getMyBankAccount}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getMyBankAccountSuccess(response?.data?.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// raise Ticket
export const updateProfileSignature = (data, handleClose, setIsloading) => async () => {
  api
    .post(SILVERBACK_API.updateProfileSignature, data)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        handleClose();
      } else {
        errorToast(response?.data?.detail, 'support');
      }
      if (setIsloading) {
        setIsloading(false);
      }
    })
    .catch((e) => {
      return errorToast(e?.response?.data?.detail, 'support');
    });
};

//download direct deposit form
export const downloadDirectDepositForm = () => async () => {
  try {
    await api.get(SILVERBACK_API.profile.downloadDirectDepositForm).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        window.open(`${process.env.REACT_APP_API_URL}${response.data.data.data}`, '_blank');

        successToast(response.data.message);
      } else {
        errorToast(response.data.detail);
      }
    });
  } catch (e) {
    errorToast(e?.response?.data?.detail);
    return console.error(e.message);
  }
};
// get Documents List
export const getDocumentsList = (request, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.profile.getDocumentsList, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDocumentsListSuccess(response.data.data));
      } else {
        errorToast(response.data.detail, 'account');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get features list
export const getFeaturesList = () => async (dispatch) => {
  api
    .get(SILVERBACK_API.getFeaturesList)
    .then((response) => {
      if (response?.status == STATUS_CODES.SUCCESS) {
        dispatch(getFeaturesListSuccess(response?.data?.data?.list));
      } else {
        errorToast(response?.data?.detail, 'support');
      }
    })
    .catch((e) => {
      return errorToast(e?.response?.data?.detail, 'support');
    });
};

export default slice.reducer;
