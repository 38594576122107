import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast, successToast } from 'component/toast';
import api from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';

const slice = createSlice({
  name: 'estate',
  initialState: {
    signatureDetailsData: null,
    isValidLink: null,
    managmentRelationshipList: [],
    managmentOptionsData: [],
    estateListData: [],
    estateDetailsData: null,
    remainingLimitData: null,
    realEstateDetail: null,
    addRealEstateData: null,
    isLoading: false
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    getDetailsForSignatureSuccess: (state, action) => {
      state.isValidLink = true;
      state.signatureDetailsData = action.payload.data.data;
    },
    getDetailsForSignatureFailure: (state) => {
      state.isValidLink = false;
    },
    getManagmentRelationshipListSuccess: (state, action) => {
      state.managmentRelationshipList = action?.payload?.data?.data;
    },
    getManagmentOptionsSuccess: (state, action) => {
      state.managmentOptionsData = action?.payload;
    },
    getEstateListSuccess: (state, action) => {
      state.estateListData = action?.payload.data;
    },
    getEstateDetailsSuccess: (state, action) => {
      state.estateDetailsData = action?.payload;
    },
    getRemainingLimitsSuccess: (state, action) => {
      state.remainingLimitData = action?.payload;
    },
    clearEstateDetailsSuccess: (state, action) => {
      state.estateDetailsData = action?.payload;
    },
    realEstateInfoSuccess: (state, action) => {
      state.realEstateDetail = action.payload.data;
    },
    realEstateInfoFailed: (state) => {
      state.isValidLink = false;
    },
    addRealEstateApiSuccess: (state, action) => {
      state.addRealEstateData = action.payload.data;
    },
    addRealEstateApiFailed: (state) => {
      state.isValidLink = false;
    },
    clearRealEstaetDetailSuccess: (state) => {
      // Reset state to initial state
      state.realEstateDetail = null;
      state.addRealEstateData = null;
      state.isLoading = false;
    }
  }
});

const {
  getDetailsForSignatureSuccess,
  getDetailsForSignatureFailure,
  getManagmentRelationshipListSuccess,
  getManagmentOptionsSuccess,
  getEstateListSuccess,
  getEstateDetailsSuccess,
  getRemainingLimitsSuccess,
  clearEstateDetailsSuccess,
  realEstateInfoSuccess,
  realEstateInfoFailed,
  clearRealEstaetDetailSuccess,
  addRealEstateApiSuccess,
  addRealEstateApiFailed,
  loadingSuccess
} = slice.actions;

export default slice.reducer;

/**********************ACTIONS************************************************ */

// get Details For Signature

export const clearRealEstaetDetail = () => async (dispatch) => {
  dispatch(clearRealEstaetDetailSuccess());
};

export const getDetailsForSignature = (token, setIsloading) => async (dispatch) => {
  const detailApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      Authorization: `Bearer ${token}`
    }
  });
  try {
    await detailApi.get(SILVERBACK_API.estate.getDetailsForSignature, {}).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getDetailsForSignatureSuccess(response?.data));
      } else if (response.status === STATUS_CODES.FAILURE) {
        dispatch(getDetailsForSignatureFailure());
      }
      setIsloading(false);
    });
  } catch (e) {
    setIsloading(false);
    dispatch(getDetailsForSignatureFailure());
    return console.error(e.message);
  }
};
export const realEstateInfo = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK_API.profile.realEstateInfoURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(realEstateInfoSuccess(response.data));
      } else {
        dispatch(realEstateInfoFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addRealEstateApi = (data, status) => async (dispatch) => {
  dispatch(loadingSuccess(status));
  try {
    await api.post(SILVERBACK_API.profile.addRealEstateApiURL, data).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data.message);
        dispatch(addRealEstateApiSuccess(response.data));
      } else {
        dispatch(addRealEstateApiFailed(response.error));
        errorToast(response.data.detail, 'states');
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// submit Signature

export const submitSignature = (id, token, setIsloading, setIsSuccess) => async () => {
  const detailApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      Authorization: `Bearer ${token}`
    }
  });
  try {
    await detailApi.get(`${SILVERBACK_API.estate.submitSignature}/${id}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response.data?.message);
        setIsSuccess(true);
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Managment Relationship List
export const getManagmentRelationshipList = () => async (dispatch) => {
  try {
    await api.get(SILVERBACK_API.estate.getManagmentRelationshipList).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getManagmentRelationshipListSuccess(response.data));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Managment Options
export const getManagmentOptions = (formType) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.estate.getManagmentOptions}/${formType}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getManagmentOptionsSuccess(response?.data?.data?.fieldOptionsData));
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// save Estate Management

export const saveEstateManagement = (request, navigate, path) => async () => {
  try {
    await api.post(SILVERBACK_API.estate.saveEstateManagement, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        navigate(path);
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Estate List

export const getEstateList = (request, setIsloading) => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.estate.getEstateList, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getEstateListSuccess(response.data.data));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// reshare Signature Link

export const reshareSignatureLink = (userMapperId, setIsloading) => async () => {
  try {
    await api
      .get(`${SILVERBACK_API.estate.reshareSignatureLink}/${userMapperId}`)
      .then((response) => {
        if (response.status === STATUS_CODES.SUCCESS) {
          successToast(response?.data?.message);
        } else {
          errorToast(response.data?.detail, 'support');
        }
        setIsloading(false);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Estate Details
export const getEstateDetails = (userMapperId, setIsloading) => async (dispatch) => {
  try {
    await api.get(`${SILVERBACK_API.estate.getEstateDetails}/${userMapperId}`).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getEstateDetailsSuccess(response?.data?.data?.data));
      } else {
        errorToast(response.data?.detail, 'support');
      }
      setIsloading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// get Remaining Limits

export const getRemainingLimits = () => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.estate.getRemainingLimits).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        dispatch(getRemainingLimitsSuccess(response?.data?.data?.limits));
      } else {
        errorToast(response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// clear estate details
export const clearEstateDetails = () => async (dispatch) => {
  dispatch(clearEstateDetailsSuccess(null));
};

// update Estate Management

export const updateEstateManagement = (request, navigate, path) => async () => {
  try {
    await api.post(SILVERBACK_API.estate.updateEstateManagement, request).then((response) => {
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast(response?.data?.message);
        navigate(path);
      } else {
        errorToast('error', response.data?.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

// compare Signatures

export const compareSignature = (request, setIsloading, setIsVerified,estate) => async () => {
  api
    .post(SILVERBACK_API.estate.compareSignature, request)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        if(!estate){
          successToast(response?.data?.message);
        }
        
        setIsVerified(true);
      }
      else{
        errorToast(response?.data?.detail);
      }
      if (setIsloading) {
        setIsloading(false);
      }
    })
    .catch((e) => {
      setIsloading(false);
      return errorToast(e?.response?.data?.detail, 'support');
    });
};
