export const color = {
  background: '#0E0E0E',
  grey: '#232426',
  textGrey: '#707070',
  lime: '#32CD32',
  highLightColor: '#1098DB',
  websiteHighLightColor: '#0887fc',
  badgeColor: '#dc3545',
  linear:
    '-webkit-linear-gradient(253deg,#848482 0%,#c0c0c0 24%,#e5e4e2 49%,#c0c0c0 73%,#848482 100%) 0% 0% no-repeat padding-box',
  darkgrey: '#c6bebe'
};
